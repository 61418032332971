import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa";
import './style.css'
import { toast } from 'react-toastify';

function Favoritos() {

    const [filmes, setFilmes] = useState([])

    useEffect(() => {
        const minhaLista = localStorage.getItem("@durezahflix");
        setFilmes(JSON.parse(minhaLista) || [])
    }, [])

    function excluirFilme(id) {
        let filtroFilmes = filmes.filter((filmes) => {
            return (filmes.id !== id)
        })

        setFilmes(filtroFilmes);
        localStorage.setItem("@durezahflix", JSON.stringify(filtroFilmes))
        toast.success("Filme removido com sucesso")
    }

    return (
        <div className='meus-filmes'>
            <h1>Tela de favoritos</h1>

            {filmes.length === 0 && <span> Você não possui nenhum filme salvo.</span>}

            <ul className='filmes-itens'>
                {filmes.map((filmes) => {
                    return (
                        <div className="favoritos">
                            <li key={filmes.id}>
                                <Link to={`/filme/${filmes.id}`}>
                                    {filmes.poster_path && <img src={`https://image.tmdb.org/t/p/w500/${filmes.poster_path}`} alt={filmes.title} />}
                                </Link>
                                <div className="rating">
                                    <FaStar /> {filmes.vote_average.toFixed(1)}
                                </div>
                                <h2>{filmes.title}</h2>
                                
                                    <button onClick={() => excluirFilme(filmes.id)}>Excluir</button>
                                
                            </li>
                        </div>
                    )
                })}
            </ul>
        </div>
    )
}

export default Favoritos;