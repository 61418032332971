import { Link } from "react-router-dom";
import { FaStar, FaHeart } from "react-icons/fa";
import { useState, useEffect } from "react";
import Filme from "../../pages/Filme";
import { toast } from 'react-toastify';

const imagesURL = "https://image.tmdb.org/t/p/w500/";

const MovieCard = ({ filme, showLink = true }) => {
  const avaliacaoArredondado = filme.vote_average.toFixed(1);

  const [Favoritado, setFavoritado] = useState(false);

  useEffect(() => {
    const minhaLista = localStorage.getItem('@durezahflix');
    const filmesSalvos = JSON.parse(minhaLista) || [];
    const hasFilme = filmesSalvos.some((filmeSalvo) => filmeSalvo.id === filme.id);
    setFavoritado(hasFilme);
  }, [filme]);
  

  function salvarFilme() {
    const minhaLista = localStorage.getItem('@durezahflix');
    let filmesSalvos = JSON.parse(minhaLista) || [];

    const hasFilme = filmesSalvos.some((filmeSalvo) => filmeSalvo.id === filme.id);

    if (hasFilme) {
      filmesSalvos = filmesSalvos.filter((filmeSalvo) => filmeSalvo.id !== filme.id);
      setFavoritado(false);
      toast.warn('Filme removido dos favoritos.');
    } else {
      filmesSalvos.push(filme);
      setFavoritado(true);
      toast.success('Filme adicionado aos favoritos.');
    }

    localStorage.setItem('@durezahflix', JSON.stringify(filmesSalvos));
  }
  return (
    <div className="movie-card">
      <Link to={`/filme/${filme.id}`}>
        <img src={imagesURL + filme.poster_path} alt={filme.title} />
      </Link>
      <div className="rating">
        <FaStar /> {avaliacaoArredondado}
      </div>
      <div className={`preferidos ${Favoritado ? 'favoritado' : ''}`} onClick={salvarFilme}>
        <FaHeart className={Favoritado ? 'heart-vermelho' : 'heart-branco'}  />
      </div>
      <h2>{filme.title}</h2>
    </div>
  );
};

export default MovieCard;
