import React, { useEffect, useState, useRef } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import './styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FaStar, FaHeart } from "react-icons/fa";
import { toast } from 'react-toastify';

function Home() {

    const [Favoritado, setFavoritado] = useState(false);
    const [filmesFavoritados, setFilmesFavoritados] = useState({});

    useEffect(() => {
        const minhaLista = localStorage.getItem('@durezahflix');
        const filmesSalvos = JSON.parse(minhaLista) || [];

        const filmesFavoritadosAtualizados = {};
        filmesSalvos.forEach((filmeSalvo) => {
            filmesFavoritadosAtualizados[filmeSalvo.id] = true;
        });

        setFilmesFavoritados(filmesFavoritadosAtualizados);
    }, []);

    const salvarFilme = (filme) => {
        const minhaLista = localStorage.getItem('@durezahflix');
        let filmesSalvos = JSON.parse(minhaLista) || [];

        const hasFilme = filmesSalvos.some((filmeSalvo) => filmeSalvo.id === filme.id);

        if (hasFilme) {
            filmesSalvos = filmesSalvos.filter((filmeSalvo) => filmeSalvo.id !== filme.id);
            setFilmesFavoritados(filmesFavoritadosAnteriores => ({ ...filmesFavoritadosAnteriores, [filme.id]: false }));
            toast.warn('Filme removido dos favoritos.');
        } else {
            filmesSalvos.push(filme);
            setFilmesFavoritados(filmesFavoritadosAnteriores => ({ ...filmesFavoritadosAnteriores, [filme.id]: true }));
            toast.success('Filme adicionado aos favoritos.');
        }

        localStorage.setItem('@durezahflix', JSON.stringify(filmesSalvos));
    };

    //Criou uma variavél chamada filmes Categorias é nela tem um objeto com quatro propriedades.
    const [filmesCategorias, setFilmesCategorias] = useState({
        nowPlaying: [],
        popular: [],
        topRated: [],
        upcoming: [],
    });
    const [loading, setLoading] = useState(true);

    const cinemasRef = useRef();
    const popularesRef = useRef();
    const melhoresRef = useRef();
    const emBreveRef = useRef();

    //Rodar os elementos mais suaveis. 
    const rodarParaEsquerda = (conteudoRef) => {
        if (conteudoRef.current) {
            conteudoRef.current.scrollBy({
                top: 0,
                left: -200,
                behavior: 'smooth',
            });
        }
    };

    const rodarParaDireita = (conteudoRef) => {
        if (conteudoRef.current) {
            conteudoRef.current.scrollBy({
                top: 0,
                left: 200,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        //Carrega os dados das categorias de um servidor.
        async function loadFilmesCategorias() {
            try {
                const apiConfiguração = {
                    params: {
                        api_key: '3500d2c3717310ca30fb42c945ebf8d9',
                        language: 'pt-BR',
                        page: 1,
                    },
                };

                const nowPlayingResponse = await api.get('movie/now_playing', apiConfiguração);
                const popularResponse = await api.get('movie/popular', apiConfiguração);
                const topRatedResponse = await api.get('movie/top_rated', apiConfiguração);
                const upcomingResponse = await api.get('movie/upcoming', apiConfiguração);

                //Armazena os dados das categorias de filmes em uma variável de estado.
                setFilmesCategorias({
                    nowPlaying: nowPlayingResponse.data.results,
                    popular: popularResponse.data.results,
                    topRated: topRatedResponse.data.results,
                    upcoming: upcomingResponse.data.results,
                });

                setLoading(false);
            } catch (error) {
                console.error('Erro ao carregar filmes:', error);
                setLoading(false);
            }
        }

        loadFilmesCategorias();
    }, []);
    //Exibe uma mensagem de carregamento se os dados ainda não foram carregados.
    if (loading) {
        return (
            <div className="loading">
                <h2>Carregando filmes...</h2>
            </div>
        );
    }

    return (

        <div className="container">

            {/*  Ele renderiza uma lista de filmes que estão atualmente nos cinemas com botões de navegação. */}
            <div className="filmes-categorias" id="cinemas">
                <h2>Nos Cinemas</h2>
                <div className="lista-filmes" ref={cinemasRef}>
                    <button className="seta-left" onClick={() => rodarParaEsquerda(cinemasRef)}>
                        &lt;
                    </button>
                    {filmesCategorias.nowPlaying.map((filme) => (
                        <article key={filme.id}>
                            <div className="conteudo-da-imagem">
                                <Link to={`/filme/${filme.id}`} style={{ display: "inline-block" }}>
                                    <img className="imagem"
                                        src={`https://image.tmdb.org/t/p/original/${filme.poster_path}`}
                                        alt={filme.title}
                                    />
                                </Link>
                                <div className="preferidos"  >
                                    <FaHeart className={filmesFavoritados[filme.id] ? 'heart-vermelho' : 'heart-branco'} onClick={() => salvarFilme(filme)} />
                                </div>
                                <div className="nota">
                                    <FaStar /> {filme.vote_average.toFixed(1)}
                                </div>

                            </div>
                            <strong>{filme.title}</strong>
                        </article>
                    ))}
                    <button className="seta-right" onClick={() => rodarParaDireita(cinemasRef)}>
                        &gt;
                    </button>
                </div>
            </div>

            {/*  Ele renderiza uma lista de filmes que são populares com botões de navegação. */}
            <div className="filmes-categorias" id="populares">
                <h2>Populares</h2>
                <div className="lista-filmes" ref={popularesRef}>
                    <button className="seta-left" onClick={() => rodarParaEsquerda(popularesRef)}>
                        &lt;
                    </button>
                    {filmesCategorias.popular.map((filme) => (
                        <article key={filme.id}>
                            <div className="conteudo-da-imagem">
                                <Link to={`/filme/${filme.id}`} style={{ display: "inline-block" }}>

                                    <img className="imagem"
                                        src={`https://image.tmdb.org/t/p/original/${filme.poster_path}`}
                                        alt={filme.title}
                                    />
                                </Link>
                                <div className="preferidos"  >
                                    <FaHeart className={filmesFavoritados[filme.id] ? 'heart-vermelho' : 'heart-branco'} onClick={() => salvarFilme(filme)} />
                                </div>
                                <div className="nota">
                                    <FaStar /> {filme.vote_average.toFixed(1)}
                                </div>
                            </div>
                            <strong>{filme.title}</strong>
                        </article>
                    ))}
                    <button className="seta-right" onClick={() => rodarParaDireita(popularesRef)}>
                        &gt;
                    </button>
                </div>
            </div>

            {/*  Ele renderiza uma lista de filmes que estão melhores avaliados com botões de navegação. */}
            <div className="filmes-categorias" id="melhores">
                <h2>Melhores Avaliados</h2>
                <div className="lista-filmes" ref={melhoresRef}>
                    <button className="seta-left" onClick={() => rodarParaEsquerda(melhoresRef)}>
                        &lt;
                    </button>
                    {filmesCategorias.topRated.map((filme) => (
                        <article key={filme.id}>
                            <div className="conteudo-da-imagem">
                                <Link to={`/filme/${filme.id}`} style={{ display: "inline-block" }}>

                                    <img className="imagem"
                                        src={`https://image.tmdb.org/t/p/original/${filme.poster_path}`}
                                        alt={filme.title}
                                    />
                                </Link>
                                <div className="preferidos"  >
                                    <FaHeart className={filmesFavoritados[filme.id] ? 'heart-vermelho' : 'heart-branco'} onClick={() => salvarFilme(filme)} />
                                </div>
                                <div className="nota">
                                    <FaStar /> {filme.vote_average.toFixed(1)}
                                </div>
                            </div>
                            <strong>{filme.title}</strong>
                        </article>
                    ))}
                    <button className="seta-right" onClick={() => rodarParaDireita(melhoresRef)}>
                        &gt;
                    </button>
                </div>
            </div>

            {/*  Ele renderiza uma lista de filmes que estão em breve serão lançados com botões de navegação. */}
            <div className="filmes-categorias" id="emBreve">
                <h2>Em Breve</h2>
                <div className="lista-filmes" ref={emBreveRef}>
                    <button className="seta-left" onClick={() => rodarParaEsquerda(emBreveRef)}>
                        &lt;
                    </button>
                    {filmesCategorias.upcoming.map((filme) => (
                        <article key={filme.id}>
                            <div className="conteudo-da-imagem">
                                <Link to={`/filme/${filme.id}`} style={{ display: "inline-block" }}>
                                    <img className="imagem"
                                        src={`https://image.tmdb.org/t/p/original/${filme.poster_path}`}
                                        alt={filme.title}
                                    />
                                </Link>
                                <div className="preferidos"  >
                                    <FaHeart className={filmesFavoritados[filme.id] ? 'heart-vermelho' : 'heart-branco'} onClick={() => salvarFilme(filme)} />
                                </div>
                                <div className="nota">
                                    <FaStar /> {filme.vote_average.toFixed(1)}
                                </div>
                            </div>
                            <strong>{filme.title}</strong>
                        </article>
                    ))}
                    <button className="seta-right" onClick={() => rodarParaDireita(emBreveRef)}>
                        &gt;
                    </button>
                </div>
            </div>
        </div >

    );
}

export default Home;

