import React from 'react';
import { BrowserRouter, Route, Switch, Routes } from 'react-router-dom'; 

import Banner from './pages/Banner';
import Home from './pages/Home';
import Filme from './pages/Filme';
import Favoritos from './pages/Favoritos';
import Erro from './pages/Erro';
import Header from './components/Header';
import Search from './components/Search';
import Footer from './components/Footer';

function RoutesApp() {
    return (
        <BrowserRouter >
            <Header/>
            <Routes>
                <Route path="/" element={<Banner />} />
                <Route path="/Home" element= {<Home/>}/>
                <Route path="Filme/:id" element={<Filme />} />
                <Route path="/Favoritos" element={<Favoritos/>} />
                <Route path="/search" element={<Search/>} />
                <Route path ="*" element = {<Erro />} /> 
            </Routes>
            <Footer/>
        </BrowserRouter>
    )
}

//O elemento Erro sempre tem que estar na última rota. 

export default RoutesApp;
