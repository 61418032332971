import React from "react";
import { HashLink } from 'react-router-hash-link';
import { FaGithub, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import "./style.css";


const Footer = () => {
    
    return (
        <footer className="footer">

            <ul className="menuItems">
                <li className="menuItem">
                    <HashLink smooth to="/#cinemas" className="hashLinkWithOffset">
                        Nos cinemas
                    </HashLink>
                </li>
                <li className="menuItem">
                    <HashLink smooth to="/#populares">
                        Populares
                    </HashLink>

                </li>
                <li className="menuItem">
                    <HashLink smooth to="/#melhores">
                        Melhores Avaliados
                    </HashLink>

                </li>
                <li className="menuItem">
                    <HashLink smooth to="/#emBreve">
                        Em breve
                    </HashLink>
                </li>
            </ul>


            <div className="centralizar-texto">
                <div className="infoTexto">
                    Filmex é um guia completo para amantes do cinema, com filmes de diversas categorias,
                    incluindo clássicos e lançamentos. Com interface intuitiva, possibilita explorar filmes nos cinemas,
                    populares e bem avaliados, além de lançamentos futuros. Os usuários podem ver detalhes do filme e
                    assistir a trailers. Também oferece uma barra de pesquisa para facilitar a busca por filmes específicos.
                </div>
            </div>

            <div className="rede-social">
                <a href="https://api.whatsapp.com/send?phone=5521979067962" target="_blank" rel="noopener noreferrer" className="icon">
                    <FaWhatsapp />
                </a>
                <a href="https://github.com/DurezahGeek" target="_blank" rel="noopener noreferrer" className="icon">
                    <FaGithub />
                </a>
                <a href="https://www.linkedin.com/in/biatriz-meirelles-70729b1a0/" target="_blank" rel="noopener noreferrer" className="icon">
                    <FaLinkedin />
                </a>
            </div>
            <div className="roda-pé">
                Filmex &copy; 2023 | &reg; Todos os direitos reservados.
            </div>



        </footer>
    );
};

export default Footer;