import {Link} from 'react-router-dom';
import './style.css'

function Erro(){
    return(
        <div className= "pagina-nao-encontrada">
            <h1>404</h1>
            <h2>Página não encontrada!</h2>
            <Link to= "/">Veja todos os Filmes!</Link>
        </div>
    )
}

export default Erro;