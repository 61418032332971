import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaHeart, FaRegHeart, FaStar } from "react-icons/fa";
import './style.css';
import api from '../../services/api';
import { toast } from 'react-toastify';
import axios from 'axios';
import YouTube from 'react-youtube';


function Filme() {
    const [filme, setFilme] = useState()
    const [loading, setLoading] = useState(true);
    const [trailerVideoId, setTrailerVideoId] = useState('');
    const [elenco, setElenco] = useState([]);
    const [filmeRecomendado, setFilmeRecomendado] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const elencoRef = useRef(null);
    const recomendadosRef = useRef(null);
    const [filmesFavoritados, setFilmesFavoritados] = useState({});

    //Rodar os elementos mais suaveis. 
    const rodarParaEsquerda = (conteudoRef) => {
        if (conteudoRef.current) {
            conteudoRef.current.scrollBy({
                top: 0,
                left: -200,
                behavior: 'smooth',
            });
        }
    };

    const rodarParaDireita = (conteudoRef) => {
        if (conteudoRef.current) {
            conteudoRef.current.scrollBy({
                top: 0,
                left: 200,
                behavior: 'smooth',
            });
        }
    };


    //Usado para retornar o scroll para sua posição inicial.
    const posicaoRecomendadosScroll = () => {
        if (recomendadosRef.current) {
            recomendadosRef.current.scrollTo(0, 0); // Scroll to the top
        }
    };

    const posicaoElencoScroll = () => {
        if (elencoRef.current) {
            elencoRef.current.scrollTo(0, 0); // Scroll to the top
        }
    };

    //Cada gênero têm uma cor.
    const genreColors = {
        Animação: 'blue',
        Aventura: 'green',
        Ação: 'red',
        Cinema: 'orange',
        TV: 'yellow',
        Comédia: 'purple',
        Crime: 'red',
        Documentário: 'violet',
        Drama: 'lightblue',
        Família: 'pink',
        Fantasia: 'turquoise',
        Faroeste: 'brown',
        FicçãoCientifica: 'lightblue',
        Guerra: 'darkgreen',
        História: 'darkred',
        Mistério: 'darkblue',
        Música: 'darkorange',
        Romance: 'lightpink',
        Terror: 'darkblack',
        Thriller: 'darkgreen',
    }

    //Carrega o filme e faz uma requisição à API para obter informações sobre o filme. 
    useEffect(() => {
        async function loadFilme() {
            try {
                const response = await api.get(`/movie/${id}`, {
                    params: {
                        api_key: '3500d2c3717310ca30fb42c945ebf8d9',
                        language: 'pt-BR',
                    },
                });

                setFilme(response.data);
                console.log(response.data);
                setLoading(false);
            } catch (error) {
                console.log('FILME NÃO ENCONTRADO');
                navigate('/', { replace: true });
            }
        }

        loadFilme();

        return () => {
            console.log('Componente foi Desmontado');
        };
    }, [navigate, id]);

    //Carrega informações sobre o elenco do filme e filtra o Elenco sendo mostrado apenas aquele que têm img de perfil.
    useEffect(() => {
        async function loadElenco() {
            try {
                const response = await api.get(`/movie/${id}/credits`, {
                    params: {
                        api_key: '3500d2c3717310ca30fb42c945ebf8d9',
                    },
                });

                const filtrarElenco = response.data.cast.filter(
                    (ator) => ator.profile_path !== null
                );

                setElenco(filtrarElenco);
            } catch (error) {
                console.log('Erro ao buscar o elenco:', error);
            }
        }

        if (!loading) {
            loadElenco();
        }
    }, [loading, id]);

    //Carrega filmes recomendados com o mesmo gênero e também coloca na posição do scroll inicial.
    useEffect(() => {
        async function loadFilmeRecomendado() {
            try {
                if (filme && filme.genres && filme.genres.length > 0) {
                    const genreIds = filme.genres.map((genre) => genre.id).join(',');
                    const response = await api.get(`/discover/movie`, {
                        params: {
                            api_key: '3500d2c3717310ca30fb42c945ebf8d9',
                            language: 'pt-BR',
                            with_genres: genreIds,
                            sort_by: 'popularity.desc',
                            include_adult: false,
                            page: 1,
                        },
                    });

                    const filteredFilmeRecomendado = response.data.results.filter(
                        (movie) => movie.id !== filme.id
                    );

                    setFilmeRecomendado(filteredFilmeRecomendado.slice(0, 8));


                } posicaoRecomendadosScroll();
                posicaoElencoScroll();
            } catch (error) {
                console.log('Erro ao buscar os filmes recomendados:', error);
            }
        }

        loadFilmeRecomendado();
    }, [filme]);

    //Realiza a busca pelo Id do video do trailer e também trailerResponse.data.items[0].id.videoId assume que o priemiro resultado é o trailer.
    useEffect(() => {
        async function TrailerVideoId() {
            try {
                const trailerResponse = await axios.get(
                    'https://www.googleapis.com/youtube/v3/search',
                    {
                        params: {
                            part: 'snippet',
                            q: `${filme.title} official trailer`,
                            key: 'AIzaSyDKSqsV9x8S5yWIWJDAaAYSwxcM0W8PDEg',
                        },
                    }
                );

                if (trailerResponse.data.items.length > 0) {
                    const videoId = trailerResponse.data.items[0].id.videoId;
                    setTrailerVideoId(videoId);
                }
            } catch (error) {
                console.log('Erro ao buscar o trailer:', error);
            }
        }


        if (!loading) {
            TrailerVideoId();
        }
    }, [filme, loading]);

    useEffect(() => {
        const minhaLista = localStorage.getItem('@durezahflix');
        const filmesSalvos = JSON.parse(minhaLista) || [];

        const filmesFavoritadosAtualizados = {};
        filmesSalvos.forEach((filmeSalvo) => {
            filmesFavoritadosAtualizados[filmeSalvo.id] = true;
        });

        setFilmesFavoritados(filmesFavoritadosAtualizados);
    }, []);

    //Essa parte será salvo o filme ou avisar que o filme jà está salvo.
    function salvarFilme(filme) {
        const minhaLista = localStorage.getItem('@durezahflix');

        let filmesSalvos = JSON.parse(minhaLista) || [];

        const hasFilme = filmesSalvos.some((filmeSalvo) => filmeSalvo.id === filme.id);
        if (hasFilme) {
            filmesSalvos = filmesSalvos.filter((filmeSalvo) => filmeSalvo.id !== filme.id);
            setFilmesFavoritados(filmesFavoritadosAnteriores => ({ ...filmesFavoritadosAnteriores, [filme.id]: false }));
            toast.warn('Filme removido dos favoritos.');
        } else {
            filmesSalvos.push(filme);
            setFilmesFavoritados(filmesFavoritadosAnteriores => ({ ...filmesFavoritadosAnteriores, [filme.id]: true }));
            toast.success('Filme adicionado aos favoritos.');
        }

        localStorage.setItem('@durezahflix', JSON.stringify(filmesSalvos));
    };

    if (loading) {
        return <div className="filme-info">Carregando detalhes</div>;
    }

    return (
        //Parte onde teremos detalhes do filme, como imagem, titulo, avaliação, lançamento, duração do filme, gênero e sinopse do filme.
        <div className="filme">
            <img className="filme-backdrop" src={`https://image.tmdb.org/t/p/original${filme ? filme.backdrop_path : ""}`} />
            <div className="filme-detalhe">
                <div className="filme-detalhe-esquerda">
                    <img className="filme-poster" src={`https://image.tmdb.org/t/p/original${filme ? filme.poster_path : ""}`} />
                </div>
                <div className="filme-detalhe-direita">
                    <div className="titulo">{filme ? filme.title : ""}</div>
                    <div className="detalhe-importante">
                        <div className="avaliação">
                            <i className="fas fa-star" />{filme.vote_average.toFixed(1)}
                        </div>
                        <div className='lançamento'>{filme ? "Lançamento: " + filme.release_date : ""}</div>
                        <div className='minutos'>{filme ? filme.runtime + " min" : ""}</div>
                    </div>
                    <div className="gênero">
                        {
                            filme && filme.genres
                                ?
                                filme.genres.map(genre => (
                                    <span
                                        key={genre.id}
                                        className="filme-gênero"
                                        id={genre.id}
                                        style={{ backgroundColor: genreColors[genre.name] || '#ccc' }}
                                    >
                                        {genre.name}
                                    </span>
                                ))
                                :
                                ""
                        }
                    </div>
                    <div className="sinopse">
                        <h3>Sinopse</h3>
                    </div>
                    <div className='sinopse-texto'>{filme ? filme.overview : ""}</div>
                    <div className="area-button">
                        <button onClick={() => salvarFilme(filme)} className="heart-icone">
                            <FaHeart className={filmesFavoritados[filme.id] ? 'heart-vermelho' : 'heart-branco'} /> 
                            {filmesFavoritados[filme.id] ? ' Favorito' : ' Favoritar'}
                        </button>
                    </div>
                </div>
            </div>

            {/* Parte do elenco será exibido imagem do ator, nome e o personagem se a lista estiver vazia mostrará nenhum ator disponível.    */}
            {elenco.length === 0 && (
                <div className="nao-disponivel">Nenhum ator disponível.</div>
            )}
            {elenco && elenco.length > 0 && (
                <div className="elenco-conteudo">
                    <button className="seta-esquerda" onClick={() => rodarParaEsquerda(elencoRef)}>
                        &lt;
                    </button>
                    <div className="elenco" ref={elencoRef}>
                        <h3>Elenco</h3>
                        <div className="elenco-lista">
                            {elenco.map((ator) => (
                                <div key={ator.id} className="elenco-item" role="button" tabIndex="-1">
                                    <img
                                        src={`https://image.tmdb.org/t/p/w500${ator.profile_path}`}
                                        alt={ator.name}
                                        className="ator-imagem"
                                    />
                                    <div className="ator-nome">{ator.name}</div>
                                    <div className="ator-personagem">Personagem: {ator.character}</div>
                                </div>
                            ))}
                        </div>
                        <button className="seta-direita" onClick={() => rodarParaDireita(elencoRef)}>
                            &gt;
                        </button>
                    </div>
                </div>
            )}


            {/* Componente carrega um vídeo do YouTube com o ID especificado no trailerVideoId. */}
            {trailerVideoId && (
                <div className='titulo-trailer'>
                    <h3>Trailer</h3>
                    <div className="trailer">
                        <YouTube
                            videoId={trailerVideoId}
                            opts={{
                                width: '100%',
                            }}
                        />
                    </div>
                </div>
            )}

            {/* Parte dos recomendados onde teremos detalhes do filmes recomendados, como imagem, titulo. */}
            {filmeRecomendado.length === 0 && (
                <div className="nao-disponivel">Nenhum filme recomendado disponível.</div>
            )}
            {filmeRecomendado && filmeRecomendado.length > 0 && (
                <div className="recomendados-conteudo" >
                    <button className="seta-esquerda" onClick={() => rodarParaEsquerda(recomendadosRef)}>
                        &lt;
                    </button >
                    <div className="recomendados" ref={recomendadosRef}>
                        <h3>Recomendados</h3>
                        <div className="recomendados-lista">
                            {filmeRecomendado.map((movie) => (
                                <div key={movie.id} className="recomendado-item" tabIndex="-1">
                                    <div className="conteudo-da-imagem">
                                        <Link to={`/filme/${movie.id}`}>
                                            <img
                                                src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                                alt={movie.title}
                                                className="recomendado-imagem"
                                            />
                                        </Link>
                                        <div className="preferidos"  >
                                            <FaHeart className={filmesFavoritados[movie.id] ? 'heart-vermelho' : 'heart-branco'} onClick={() => salvarFilme(movie)} />
                                        </div>
                                        <div className="nota">
                                            <FaStar /> {filme.vote_average.toFixed(1)}
                                        </div>
                                    </div>
                                    <div className="recomendado-nome">{movie.title}</div>
                                </div>
                            ))}
                        </div>
                        <button className="seta-direita" onClick={() => rodarParaDireita(recomendadosRef)}>
                            &gt;
                        </button>
                    </div>
                </div>
            )}
        </div>

    )
}

export default Filme;

