import { useState, useEffect } from "react";
import './style.css';
import { Link } from 'react-router-dom';
import { BiCameraMovie } from "react-icons/bi";
import { HiOutlineSearch } from "react-icons/hi";
import { SlMenu } from "react-icons/sl";
import { VscChromeClose } from "react-icons/vsc";
import { useNavigate, useLocation } from "react-router-dom";
import { FaHeart } from "react-icons/fa";

function Header() {
    const [show, setShow] = useState("top");
    const [lastScrollY, setLastScrollY] = useState(0);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [query, setQuery] = useState("");
    const [search, setSearch] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const controlNavbar = () => {
        if (window.scrollY > 200) {
            if (window.scrollY > lastScrollY && !mobileMenu) {
                setShow("hide");
            } else {
                setShow("show");
            }
        } else {
            setShow("top");
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    }, [lastScrollY]);


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitting search");

        if (!query) return;

        navigate(`/search?q=${query}`, { replace: true });
        setSearch(false);
    };


    const openSearch = () => {
        console.log("Opening search");
        setMobileMenu(false);
        setSearch(true);
        setQuery("");
    };

    const handleCloseSearch = () => {
        setSearch(false);
    };

    const openMobileMenu = () => {
        setMobileMenu(true);
        setSearch(false);
    };

    const navigationHandler = (type) => {
        if (type === "movie") {
            navigate("/explore/movie");
        } else {
            navigate("/favoritos");
        }
        setMobileMenu(false);
    };

    return (
        <header className={`header ${mobileMenu ? "mobileView" : ""} ${show}`}>
            <div className="logo" onClick={() => navigate("/")}>
                <a href="/" className="logo">
                    <img src="claquete.png" style={{ maxWidth: "20px", marginRight: "8px" }}/>
                    Filmex
                </a>
            </div>
            <ul className="menuItems">
                <li
                    className="menuItem1"
                    onClick={() => navigationHandler("favoritos")}
                >
                    <FaHeart style={{ color: "red", marginRight: "8px" }} />  Meus Filmes
                </li>
                <li className="menuItem">
                    <HiOutlineSearch onClick={openSearch} />
                </li>
            </ul>

            <div className="mobileMenuItems">
                <HiOutlineSearch onClick={openSearch} style={{ fontSize: "24px" }} />
                {mobileMenu ? (
                    <VscChromeClose onClick={() => setMobileMenu(false)} style={{ fontSize: "24px" }} />
                ) : (
                    <SlMenu onClick={openMobileMenu} style={{ fontSize: "24px" }} />
                )}
            </div>

            {search && (
                <div className="searchBar">

                    <form className="searchInput" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Busque um filme..."
                            onChange={(e) => setQuery(e.target.value)}
                            value={query}
                        />

                        <VscChromeClose
                            onClick={() => setSearch(false)}
                        />
                    </form>

                </div>
            )
            }

        </header >
    );
}

export default Header;