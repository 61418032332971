import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MovieCard from "./MovieCard";

const searchURL = "https://api.themoviedb.org/3/search/movie";
const apiKey = "3500d2c3717310ca30fb42c945ebf8d9";

import "./MoviesGrid.css";

const Search = () => {
  const [searchParams] = useSearchParams();

  const [filmes, setFilmes] = useState([]);
  const query = searchParams.get("q");

  const getFilmesPesquisados = async (url) => {
    const res = await fetch(url);
    const data = await res.json();
    setFilmes(data.results);
  };

  useEffect(() => {
    const searchWithQueryURL = `${searchURL}?api_key=${apiKey}&query=${query}&language=pt-BR`;
    getFilmesPesquisados(searchWithQueryURL);
  }, [query]);

  return (
    <div className="container">
      <h2 className="title">
        Pesquisando por: <span className="query-text">{query}</span>
      </h2>
      <div className="movies-container">
        {filmes.length > 0 &&
          filmes
            .filter((filme) => filme.vote_average > 3.0)
            .map((filme) => <MovieCard key={filme.id} filme={filme} />)}
      </div>
    </div>
  );
};

export default Search;