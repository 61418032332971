import React, { useEffect, useState } from "react";
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import Home from "../Home";
import { format } from "date-fns";
import { BiGlobe } from "react-icons/bi";

const Banner = () => {

    const [nowPlayingFilmes, setNowPlayingFilmes] = useState([]);
    const [genero, setGenero] = useState({});

    const pegarTamanhoTela = () => {
        return window.innerWidth;
    };
    const descrição = (filme) => {
        const tamanhoTela = pegarTamanhoTela();


        if (tamanhoTela <= 768) {
            return null;
        }

        return (
            <>
                {filme && filme.overview && <h3>{filme.overview}</h3>}
            </>
        );
    };

    useEffect(() => {
        fetch("https://api.themoviedb.org/3/movie/now_playing?api_key=3500d2c3717310ca30fb42c945ebf8d9&language=pt-BR")
            .then(res => res.json())
            .then(data => setNowPlayingFilmes(data.results.slice(0, 7)));
    }, []);

    fetch("https://api.themoviedb.org/3/genre/movie/list?api_key=3500d2c3717310ca30fb42c945ebf8d9&language=pt-BR")
        .then(res => res.json())
        .then(data => {
            const gênero = {};
            data.genres.forEach(genero => {
                gênero[genero.id] = genero.name;
            });
            setGenero(gênero);
        });

    const pegargenero = (generoIds) => {
        return generoIds.map(generoId => genero[generoId]).join(" •  ");
    };

    const formatarDataBrasileira = (data) => {
        return format(new Date(data), 'dd/MM/yyyy');
    };

    return (
        <>
            <div className="body">
                <Carousel
                    showThumbs={false}
                    autoPlay={true}
                    transitionTime={3}
                    infiniteLoop={true}
                    showStatus={false}
                >
                    {
                        nowPlayingFilmes.map((filme) => (
                            <Link
                                key={filme.id} // Add the unique key prop here
                                style={{ textDecoration: "none", color: "white" }}
                                to={`/filme/${filme.id}`}
                            >
                                <div className="Imagem">
                                    <img src={`https://image.tmdb.org/t/p/original${filme && filme.backdrop_path}`} />
                                </div>
                                <div className="texto-dentro-da-img">
                                    <div className="titulo"><h2>{filme ? filme.title : ""}</h2></div>
                                    <div className="gênero">
                                        {filme && filme.genre_ids ? pegargenero(filme.genre_ids) : ""}
                                    </div>
                                    <div className="data-de-lançamento">
                                        {filme ? formatarDataBrasileira(filme.release_date) : ""}
                                        <span className="avaliação1">
                                            {" "}<i className="fas fa-star" />
                                            {""} {filme ? filme.vote_average : ""} {""}
                                        </span>
                                    </div>

                                    <div className="descrição">{descrição(filme)}</div>

                                </div>
                            </Link>
                        ))
                    }
                </Carousel>
                <Home />
            </div>
        </>
    )
}

export default Banner;
